import { Stack, ITheme, DefaultButton } from "@fluentui/react";
import {
  BooleanCellRenderer,
  IOnsiGridState,
  OnsiGrid,
  RowMenuHeaderGrid,
} from "components/Grid";
import Loading from "components/Loading";
import { ColDef, RowNode } from "ag-grid-community";
import { IReportListItem } from "types";
import { ReportActionMenu } from "../ReportActionMenu";
import ReportStore from "stores/reports/reportStore";
import Stores from "stores";
import { inject } from "mobx-react";
import { getStyles } from "./reportList.styleNames";
import CompanyStore from "stores/companies/companyStore";
import { storeHelper } from "utils";

export interface IReportListProps {
  reportStore?: ReportStore;
  companyStore?: CompanyStore;
  theme?: ITheme;
  onDownloadReport: (id: string, row?: any) => void;
  onSelectionChanged: (reports: IReportListItem[]) => void;
  onOpenReport: (report: IReportListItem) => void;
}
@inject(Stores.ReportStore, Stores.CompanyStore)
export default class ReportList extends OnsiGrid<
  IReportListProps,
  IOnsiGridState
> {
  private readonly booleanCellRendererComponent = "booleanCellRenderer";
  private readonly linkCellRendererComponent = "linkCellRenderer";
  private companyId: any;
  private employeeId: any;

  constructor(props: IReportListProps) {
    super(props);
    this.state = {
      rowData: [],
      loading: true,
      heightContent: this.defaultEmptyRowHeight,
    };
  }

  async loadGridData(): Promise<void> {}
  
  async reloadGridData(): Promise<void> {   
    this.setState({ loading: true });
    await this.loadReportItems();
    this.setState({ loading: false });
  }

  async loadReportItems(): Promise<void> {
    this.companyId = this.props.companyStore?.currentCompany?.id!;
    this.employeeId = this.props.companyStore?.currentCompany?.employeeId!;
    await this.props.reportStore
      ?.getReportList(this.companyId, this.employeeId)
      .catch((e: any) => {
        console.error("App.componentDidMount() failed");
        storeHelper.onSubmitError(e);
      });
    this.setState(
      {
        rowData: this.props.reportStore?.reportList ?? [],
      },
      () => {
        this.calcAndSetHeightGrid(
          this.state.rowData.length,
          this.defaultRowHeight
        );
      }
    );
  }

  configureGridOptions(): void {
    this.gridOptions = {
      components: {
        actionFieldHeaderComponent: RowMenuHeaderGrid,
        booleanCellRenderer: BooleanCellRenderer,
        linkCellRenderer: LinkCellRenderer,
      },
      rowSelection: "multiple",
      getRowHeight: (params: any) => {
        return undefined;
      },
      onGridReady: (params: any) => {
        this.setDefaultGridApiSettings(params);
        this.calcAndSetHeightGrid(
          this.state.rowData.length,
          this.defaultRowHeight
        );
        params.api.sizeColumnsToFit();
      },
      onSelectionChanged: (params: any) =>
        this.props.onSelectionChanged(
          params.api.getSelectedNodes().map((node: RowNode) => {
            return node.data;
          })
        ),
      suppressPaginationPanel: true,
    };
  }

  render() {
    if (this.state.loading) return <Loading />;

    return <Stack>{this.onRenderGrid()}</Stack>;
  }

  getColumns(): ColDef[] {
    return [
      {
        headerName: "Date",
        field: "date",
        minWidth: 140,
        maxWidth: 140,
        headerCheckboxSelection: true,
        checkboxSelection: () => true,
        cellRenderer: this.linkCellRendererComponent,
        cellRendererParams: {
          onOpenReport: this.props.onOpenReport,
        },
      },
      {
        headerName: "Description",
        field: "description",
        minWidth: 100,
        cellRenderer: this.linkCellRendererComponent,
        cellRendererParams: {
          onOpenReport: this.props.onOpenReport,
        },
      },
      {
        headerName: "Viewed",
        field: "isViewed",
        minWidth: 150,
        maxWidth: 150,
        cellRenderer: this.booleanCellRendererComponent,
      },
      {
        headerComponent: "actionFieldHeaderComponent",
        minWidth: 60,
        maxWidth: 60,
        cellRenderer: ReportActionMenu,
        cellRendererParams: {
          onDownloadReport: this.props.onDownloadReport,
        },
      },
    ];
  }
}

export const LinkCellRenderer: React.FC<any> = (props) => {
  const { linkButtonStyles } = getStyles();
  return (
    <DefaultButton
      styles={linkButtonStyles}
      onClick={() => props.onOpenReport(props.data)}
    >
      {props.value}
    </DefaultButton>
  );
};
