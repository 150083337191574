import React from "react";
import { ITheme } from "@fluentui/react";
import { ColDef } from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../styles/agGrid/sass/ag-grid-custom-theme.scss";
import Loading from "components/Loading";
import "../../../styles/agGrid/css/ag-grid-custom-styles.css";

export interface OnsiAgGridProps extends AgGridReactProps {
  skipAutoFocusOnEditableColumn?: boolean;
}

export interface IOnsiGridProps {
  onAdd?: () => void;
  onEdit?: (id: string, row?: any) => void;
  onDelete?: (id: string, row?: any) => void;
  theme?: ITheme;
  disabled?: boolean;
}

export interface IOnsiGridState {
  loading: boolean;
  rowData: any[];
  heightContent: any;
  widthContent?: any;
  gridTheme?: string;
  sortColumn?: string;
}

export abstract class OnsiGrid<
  P extends IOnsiGridProps,
  S extends IOnsiGridState
> extends React.Component<P, S> {
  private readonly defaultGridWidth = "100%";
  private defaultColDef: ColDef;
  private agGridOptions: AgGridReactProps | undefined;
  protected firstEditableCol?: number;

  public readonly defaultHeight = 36;
  public readonly defaultRowHeight = 42;
  public readonly defaultPaddingHeight = 3;
  public readonly defaultEmptyRowHeight = 120;
  public readonly defaultBodyHeight = 276;
  public readonly defaultGridTheme = "ag-theme-alpine";
  public readonly defaultDisabledGridTheme = "ag-theme-alpine-dark";

  gridApi: any;
  columnApi: any;

  constructor(props: P) {
    super(props);
    this.defaultColDef = {
      editable: false,
      resizable: true,
      sortable: true,
      filter: true,
      flex: 1,
    };
    this.configureGridOptions();
  }

  protected get defaultColumnDef(): ColDef {
    return this.defaultColDef;
  }

  protected set defaultColumnDef(def: ColDef) {
    this.defaultColDef = def;
  }

  protected get gridOptions(): OnsiAgGridProps | undefined {
    return this.agGridOptions;
  }

  protected set gridOptions(options: OnsiAgGridProps | undefined) {
    this.agGridOptions = options;
  }

  gridRowData(): Array<any> {
    return this.state.rowData;
  }

  async componentDidMount() {
    await this.loadGridData();
  }

  onRenderGrid = (): JSX.Element => {
    if (this.state.loading) return <Loading />;

    return (
      <div
        className={
          this.state.gridTheme ? this.state.gridTheme : this.defaultGridTheme
        }
        style={{
          height: this.state.heightContent,
          width: this.state.widthContent
            ? this.state.widthContent
            : this.defaultGridWidth,
        }}
      >
        <AgGridReact
          columnDefs={this.getGridColumns()}
          defaultColDef={this.defaultColumnDef}
          onGridReady={
            this.gridOptions?.onGridReady
              ? this.gridOptions?.onGridReady
              : this.onGridReady
          }
          rowData={this.state.rowData}
          components={this.gridOptions?.components}
          rowSelection={this.gridOptions?.rowSelection}
          animateRows={this.gridOptions?.animateRows}
          treeData={this.gridOptions?.treeData}
          getDataPath={this.gridOptions?.getDataPath}
          groupDefaultExpanded={this.gridOptions?.groupDefaultExpanded}
          autoGroupColumnDef={this.gridOptions?.autoGroupColumnDef}
          getRowNodeId={this.gridOptions?.getRowNodeId}
          onRowDragEnd={this.gridOptions?.onRowDragEnd}
          getRowHeight={
            this.gridOptions?.getRowHeight
              ? this.gridOptions?.getRowHeight
              : this.onGetRowHeight
          }
          onRowGroupOpened={this.gridOptions?.onRowGroupOpened}
          suppressRowTransform={this.gridOptions?.suppressRowTransform}
          masterDetail={this.gridOptions?.masterDetail}
          detailCellRenderer={this.gridOptions?.detailCellRenderer}
          detailCellRendererParams={this.gridOptions?.detailCellRendererParams}
          onCellValueChanged={this.gridOptions?.onCellValueChanged}
          isExternalFilterPresent={this.gridOptions?.isExternalFilterPresent}
          doesExternalFilterPass={this.gridOptions?.doesExternalFilterPass}
          onFilterChanged={this.gridOptions?.onFilterChanged}
          pagination={this.gridOptions?.pagination}
          onPaginationChanged={this.gridOptions?.onPaginationChanged}
          suppressPaginationPanel={this.gridOptions?.suppressPaginationPanel}
          suppressScrollOnNewData={this.gridOptions?.suppressScrollOnNewData}
          headerHeight={this.gridOptions?.headerHeight || this.defaultHeight}
          suppressClickEdit={this.gridOptions?.suppressClickEdit}
          singleClickEdit={this.gridOptions?.singleClickEdit}
          onCellEditingStopped={this.gridOptions?.onCellEditingStopped}
          onRowDoubleClicked={this.gridOptions?.onRowDoubleClicked}
          onFirstDataRendered={this.headerHeightSetter}
          onColumnResized={this.headerHeightSetter}
          overlayNoRowsTemplate={this.gridOptions?.overlayNoRowsTemplate}
          stopEditingWhenCellsLoseFocus={
            this.gridOptions?.stopEditingWhenCellsLoseFocus
          }
          suppressCellFocus={true}
          rowClassRules={this.gridOptions?.rowClassRules}
          detailRowAutoHeight={this.gridOptions?.detailRowAutoHeight}
          overlayLoadingTemplate={this.gridOptions?.overlayLoadingTemplate}
          onSortChanged={this.gridOptions?.onSortChanged}
          onSelectionChanged={this.gridOptions?.onSelectionChanged}
          onRowSelected={this.gridOptions?.onRowSelected}
          tooltipShowDelay={this.gridOptions?.tooltipShowDelay}
          suppressHorizontalScroll={this.gridOptions?.suppressHorizontalScroll}
        />
      </div>
    );
  };

  setDefaultGridApiSettings = (params: any) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  };

  expandAll = () => {
    this.gridApi.forEachNode((node: any) => {
      node.expanded = true;
    });
    this.gridApi.onGroupExpandedOrCollapsed();
  };

  collapseAll = () => {
    this.gridApi.forEachNode((node: any) => {
      node.expanded = false;
    });
    this.gridApi.onGroupExpandedOrCollapsed();
  };

  calcAndSetHeightGrid(
    rowCount: number,
    rowHeight: number = this.defaultHeight,
    hasFooter: boolean = true,
    defaultHeight: number = this.defaultBodyHeight,
    paddingTop: number = this.defaultPaddingHeight,
    totalRowHeight?: number
  ) {
    if (rowCount <= 0) {
      this.setState({
        heightContent: this.defaultEmptyRowHeight,
      });
      return;
    }
    const maxHeight = document.documentElement.clientHeight - defaultHeight;
    const allRowHeight = totalRowHeight ? totalRowHeight : rowCount * rowHeight;
    const currentHeight =
      allRowHeight + (hasFooter ? this.defaultHeight : 0) + paddingTop;
    const heightContent = currentHeight > maxHeight ? maxHeight : currentHeight;
    this.setState({
      heightContent: heightContent,
    });
  }

  /*
  To get template of mltiLine Header
  Example of use:

  const column = {
    resizable: true,
    headerName: 'Some heater text',
    headerComponentParams: {
      template: this.getMultiLineHeaderTemplate(),
    },
  */
  getMultiLineHeaderTemplate = (): string => {
    return (
      '<div class="ag-cell-label-container" role="presentation">' +
      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
      '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
      '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
      "  </div>" +
      "</div>"
    );
  };

  protected recalculateHeightContent = (adjustedValue: number): string => {
    let heightContent: string = this.state.heightContent;
    heightContent = heightContent.replace("px", "");

    let heightContentNumber = Number(heightContent);
    heightContentNumber = heightContentNumber + adjustedValue;

    heightContent = heightContentNumber.toString() + "px";
    return heightContent;
  };

  setEditableColumnFocused = () => {
    if (!this.columnApi || !this.gridApi) return;

    let collumnToFocus = undefined;
    if (this.firstEditableCol)
      collumnToFocus =
        this.columnApi.getAllDisplayedColumns()[this.firstEditableCol];
    else
      collumnToFocus = this.columnApi
        .getAllDisplayedColumns()
        .find((col: any) => col.userProvidedColDef.editable === true);
    if (!collumnToFocus) return;

    this.gridApi.ensureColumnVisible(collumnToFocus.colId);
    this.gridApi.setFocusedCell(0, collumnToFocus.colId, "top");
    this.gridApi.startEditingCell({
      rowIndex: 0,
      colKey: collumnToFocus.colId,
    });
  };

  async clearGridData(): Promise<void> {
    if (!this.columnApi || !this.gridApi) return;

    this.gridApi.setRowData([]);
  }

  getGridRowData = (id: string): any => {
    return this.state.rowData.find((item: any) => item.id === id);
  };

  abstract loadGridData(): Promise<void>;

  abstract configureGridOptions(): void;

  abstract getColumns(): ColDef[];

  private onGridReady = (params: any) => {
    this.setDefaultGridApiSettings(params);
    if (!this.gridOptions?.skipAutoFocusOnEditableColumn)
      this.setEditableColumnFocused();
  };

  private onGetRowHeight = () => {
    return this.defaultHeight;
  };

  private headerHeightSetter(params: any) {
    const columnHeaderTexts = Array.from(
      document.querySelectorAll(".ag-header-cell-text")
    );

    if (columnHeaderTexts.length > 0) {
      const clientHeights = columnHeaderTexts.map(
        (headerText) => headerText.clientHeight
      );

      if (
        params.api.gridOptionsWrapper.gridOptions.headerHeight !==
        this.defaultHeight
      ) {
        params.api.setHeaderHeight(
          params.api.gridOptionsWrapper.gridOptions.headerHeight
        );
      } else {
        const padding = 20;
        const maxClientHeight = clientHeights.reduce((x, y) => Math.max(x, y));
        const height = maxClientHeight + padding;
        params.api.setHeaderHeight(height);
      }
      params.api.resetRowHeights();
    }
  }

  /*private componentStateChanged = (event: any) => {
    if (this.gridOptions?.onComponentStateChanged) {
      this.gridOptions?.onComponentStateChanged(event);
    }
    if (!this.gridOptions?.skipAutoFocusOnEditableColumn) {
      this.setEditableColumnFocused();
    }
  };*/

  private getGridColumns(): ColDef[] {
    const columns = this.getColumns();
    columns.forEach((column: ColDef) => {
      if (
        column.cellRenderer &&
        column.cellRendererParams?.textAlign === "right"
      )
        column.cellClass = "ag-cell-right-aligned";
      if (
        column.cellRenderer &&
        column.cellRendererParams?.textAlign === "center"
      )
        column.cellClass = "ag-cell-center-aligned";
    });
    return columns;
  }
}
